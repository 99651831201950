<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
      <router-view />
  </v-app>

</template>

<script>
//import { mapActions } from 'vuex';

export default {
  name: 'App',

  components: {
    
  },
  methods: {
    //...mapActions(['validarToken']),
  },
  computed: {
  },

  async created() {
    console.log('App cargada...', this.$route.params)
    //this.validarToken()
    /* if(this.$route.params.token){
        console.log('Verificando Usuario...')
        await this.validarToken(this.$route.params.token)
        console.log('Verificación terminada...')
    } */
  } 

};
</script>
