import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        Sidebar_drawer: null,
        Customizer_drawer: false,
        SidebarColor: 'white',
        SidebarBg: '',
        usrData: '',
        typeToken: 'Bearer',
        usrWallet: '',
        usrToken: '',
        //appConfig: ''
      },
    mutations: {
        SET_SIDEBAR_DRAWER (state, payload) {
            state.Sidebar_drawer = payload
        },
        SET_CUSTOMIZER_DRAWER (state, payload) {
            state.Customizer_drawer = payload
        },
        SET_SIDEBAR_COLOR (state, payload) {
            state.SidebarColor = payload
        },
        set_usrWallet (state, payload) {
            state.usrWallet = payload
        },
        set_usrData (state, payload){
            state.usrData = payload
        },
        set_usrToken (state, payload){
            state.usrToken = payload
        },
        /* set_appConfig (state, payload){
            state.appConfig = payload
        }, */
    },
    actions: {
        async validarToken({ dispatch, commit }, token) {
            
            commit('set_usrToken', token)
            localStorage.setItem('usrToken', token)
            //console.log('Token: ', token)
            //console.log('this.usrToken: ', this.usrToken)
            //console.log('store.usrToken: ', this.state.usrToken)
            //Obtener la informacion de los planes
            let getUser = await axios({
                method: 'POST',
                url: `${process.env.VUE_APP_API_URL}/api/users/profile`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.state.usrToken,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                //this.loading = false
                let respUser = response.data
                //console.log('Withdrawals...', respBalance);
                if (!respUser.success){
                    console.error('respUser Error: ', respUser)
                }
                else {
                    //Obtener la configuracion de la app
                    await dispatch('getConfig')

                    let user = respUser.user
                    let getUsrWallet = await axios({
                        method: 'GET',
                        url: `${process.env.VUE_APP_API_URL}/api/users/${user.id}/wallets`,
                        headers: { 
                            "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + token,
                            //"Accept": "application/json"
                        }
                    })
                    .then(async response => {
                        //this.loading = false
                        let respWallet = response.data
                        //console.log('respWallet...', respWallet);
                        if (!respWallet.success){
                            console.error('respWallet Error: ', respWallet)
                        }
                        else {
                            let infoWallet = respWallet.data[0] 
                            if(infoWallet){
                                //console.log('set_usrWallet', infoWallet.pivot.wallet)
                                commit('set_usrWallet', infoWallet.pivot.wallet)
                                return {
                                    co_wallet: infoWallet.co_wallet,
                                    nb_wallet: infoWallet.nb_wallet,
                                    wallet: infoWallet.pivot.wallet,
                                    id_wallet: infoWallet.pivot.id
                                }
                            }
                        }
                    })
                    .catch(error => {
                        console.error('respWallet Error: ', error)
                        //let message = !error.response ? error.message : error.response.data.msg;
                        return {}
                    })
                    //console.log('User: ', user)
                    let dataUser = {
                        id: user.id,
                        co_user: user.co_users,
                        referido: user.co_referido,
                        nombre: user.name,
                        email: user.email,
                        link_referencia: user.link_referencia,
                        email_verificado: user.email_verified_at,
                        wallet: getUsrWallet,
                        nb_perfil: user.nb_perfil
                    }
                    commit('set_usrData', dataUser)
                    localStorage.setItem('user', JSON.stringify(dataUser))
                    return true
                }
            })
            .catch(error => {
                console.error('respUser Error: ', error)
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
                return false
            })

            //console.log('getUser: ', getUser)
            if(getUser)
                router.push('/')
            else
                router.push('/pages/alerts')

        },
        async getConfig() {
            await axios({
                method: 'GET',
                url: `${process.env.VUE_APP_API_URL}/api/config`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.state.usrToken,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                //this.loading = false
                let respConfig = response.data
                //console.log('respConfig...', respConfig);
                if (!respConfig.success){
                    console.error('respConfig Error: ', respConfig)
                }
                else {
                    let configApp = respConfig.data
                    if(configApp){
                        //console.log('set_appConfig', configApp)
                        //commit('set_appConfig', JSON.stringify(configApp))
                        localStorage.setItem('vars', JSON.stringify(configApp))
                    }
                }
            })
            .catch(error => {
                console.error('respConfig Error: ', error)
                //let message = !error.response ? error.message : error.response.data.msg;
            })
        }
    },
})