import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './plugins/base'
require('dotenv').config()
import Cryptoicon from 'vue-cryptoicon';
import icons from 'vue-cryptoicon/src/icons';
Cryptoicon.add(icons);
Vue.use(Cryptoicon); 

//console.log(process.env)

Vue.config.productionTip = false

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')
