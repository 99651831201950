import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// create object router with the valid initialization
const router = new Router({   
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: 'dashboard/user_dashboard',
            component: () => import('@/layouts/Layout'),
            children: [
                // Components
                {
                    path: "user/:token",
                    component: () => import(/* webpackChunkName: "home" */ "@/views/pages/VerificarUsuario"),
                },
                {
                    name: 'Dashboard',
                    path: 'dashboard/user_dashboard',
                    component: () => import('@/views/dashboard/BasicDashboard'),
                    meta: {rutaProtegida: true}
                },
                {
                    name: 'Depositos',
                    path: 'pages/depositos',
                    component: () => import('@/views/pages/Depositos'),
                    meta: {rutaProtegida: true}
                },
                {
                    name: 'Invertir',
                    path: 'pages/invertir',
                    component: () => import('@/views/pages/Invertir'),
                    meta: {rutaProtegida: true}
                },
                {
                    name: 'Profile',
                    path: 'pages/profile',
                    component: () => import('@/views/pages/Profile'),
                    meta: {rutaProtegida: true}
                },
                {
                    name: 'Referidos',
                    path: 'pages/referidos',
                    component: () => import('@/views/pages/Referidos'),
                    meta: {rutaProtegida: true}
                },
                {
                    name: 'Retiros',
                    path: 'pages/retiros',
                    component: () => import('@/views/pages/Retiros'),
                    meta: {rutaProtegida: true}
                },
                {
                    name: 'Transacciones',
                    path: 'pages/transacciones',
                    component: () => import('@/views/pages/Transacciones'),
                    meta: {rutaProtegida: true}
                },
                {
                    name: 'Play',
                    path: 'pages/playticktack',
                    component: () => import('@/views/pages/Play'),
                    meta: {rutaProtegida: true}
                }, 
                {
                    name: 'GestionDepositos',
                    path: 'pages/administracion/gestdepositos',
                    component: () => import('@/views/pages/administracion/GestionDepositos'),
                    meta: {rutaProtegida: true}
                },
                {
                    name: 'GestionRetiros',
                    path: 'pages/administracion/gestretiros',
                    component: () => import('@/views/pages/administracion/GestionRetiros'),
                    meta: {rutaProtegida: true}
                },
                {
                    name: 'Tipo Wallet Users',
                    path: 'pages/administracion/tipowallets',
                    component: () => import('@/views/pages/administracion/Tipowallets'),
                    meta: {rutaProtegida: true}
                },
                {
                    name: 'Tipo Wallet Master',
                    path: 'pages/administracion/tipowalletsmaster',
                    component: () => import('@/views/pages/administracion/Tipowalletsmaster'),
                    meta: {rutaProtegida: true}
                },
                {
                    name: 'List Wallets Master',
                    path: 'pages/administracion/walletsmaster',
                    component: () => import('@/views/pages/administracion/Walletsmaster'),
                    meta: {rutaProtegida: true}
                },
                {
                    name: 'Wallets Master',
                    path: 'pages/administracion/frmwalletsmaster',
                    component: () => import('@/views/pages/administracion/Frmwalletsmaster'),
                    meta: {rutaProtegida: true}
                },
                {
                    name: 'Wallets Master Edit',
                    path: 'pages/administracion/frmwalletsmaster/:id',
                    component: () => import('@/views/pages/administracion/Frmwalletsmaster'),
                    meta: {rutaProtegida: true}
                },
                {
                    name: 'Configuracion',
                    path: 'pages/administracion/configuracion',
                    component: () => import('@/views/pages/administracion/Configuracion'),
                    meta: {rutaProtegida: true}
                },
            ]
        },
        {
            path: "/:token",
            component: () => import(/* webpackChunkName: "home" */ "@/views/pages/VerificarUsuario"),
        },

    ],
});

// add the beforeEach hook
router.beforeEach((to, from, next) => {
//console.log(to.meta.rutaProtegida)
//console.log('valor de token en store',store)
    if(to.meta.rutaProtegida) {
        try {
            //console.log('que trae token', localStorage.getItem("usrToken"))
            const eltoken = localStorage.getItem("usrToken")
            if (eltoken !== '' && eltoken !== null) {
                //console.log('ls_token', ls_token)
                next()
            } else {
                console.log('Token no existe')
                next('/pages/alerts')
            }

        } catch {
            console.error('esta dando un catch')
            next('/pages/alerts')
        }
    } 
    else {
        next()
    }
});
  
  
  // export router as default
  export default router;